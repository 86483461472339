import React, { useEffect } from 'react';
import boyUImg from "../../assets/images/boyUImg.png";

const BoyUOnline = () => {

  // Set the page Title for SEO purpose
  useEffect(() => {
    document.title = "BoyU Online | Omelatte";
  }, []);

  return (
    <>
      <section className='serviceDetailSection'>
        <div className='container'>
          <header className='sectionHeader txtCenter'>
            <div className='imgWrap txtCenter portfolioImgWrap'>
              <img src={boyUImg} className='imgFluid' alt="description" />
              <ul className='webLink'>
                <li className='active'><a href="https://boyu.online/" target="_blank" rel='noreferrer'>visit website</a></li>
              </ul>
            </div>
            <h1 className='h1'>
              BoyU Online
            </h1>
          </header>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
            <div className='wrapIner'>
              <span className='txtTag txtUppercase'>Over View</span>
              <div className='decpWrap'>
                <p>
                  This health and wellness store is crafted for the tech-savvy consumer, offering advanced
                  product filters and a wishlist feature to improve user experience. With a minimalistic,
                  modern design and quick-view options, it caters to the growing demand for clean aesthetics
                  in online shopping. Payments are supported via Shopify Payments and Stripe, and the layout
                  adapts seamlessly for mobile, making it ideal for browsing on the go. This store’s user-friendly
                  checkout and simple navigation create a smooth, engaging experience.
                </p>
              </div>
              <div className='portfolioIcons'>
                <i class="fa-brands fa-shopify shopifyIcon"></i>
              </div>
            </div>
          </article>
          <article className="serviceWidget srcvWdgAlt">
            <div className='wrapIner'>
              <h2 className='h2 clrTheme'>Why Choose Cloud Hosting?</h2>
              <ul className='ftrList'>
                <li><span>Scalability:</span> Easily adjust resources as your business grows.</li>
                <li><span>Reliability:</span> 24/7 availability and data redundancy.</li>
                <li><span>Security:</span> Robust data protection and compliance.</li>
                <li><span>Cost-Efficiency:</span> Pay only for the resources you use</li>
                <li><span>Flexibility:</span> Scale up or down based on demand.</li>
                <li>Our cloud hosting service offers numerous advantages, including scalability, reliability, security, cost-efficiency, and flexibility.</li>
              </ul>
            </div>
          </article>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
            <div className='wrapIner'>
              <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
              <h2 className='h2'>Why Opt for Omelatte?</h2>
              <div className='decpWrap'>
                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
}

export default BoyUOnline;