import React, { useEffect } from 'react';
import Img01 from '../../assets/images/img03.svg';

const MvpDevelopment = () => {

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Cloud Hosting | Omelatte";  
	}, []);

    return (
        <>
            <section className='serviceDetailSection'>
                <div className='container'>
                    <header className='sectionHeader txtCenter'>
                        <div className='imgWrap txtCenter'>
                            <img src={Img01} className='imgFluid' alt="image description" />
                        </div>
                        <h1 className='h1'>
                            <span className='txtOutline dBlock'>Cloud </span>
                            Hosting
                        </h1>
                        <p>Secure and scalable cloud solutions.</p>
                    </header>
                    <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
                        <div className='wrapIner'>
                            <span className='txtTag txtUppercase'>Start a project</span>
                            <h2 className='h2'>Reliable Cloud Infrastructure & Hosting</h2>
                            <div className='decpWrap'>
                                <p>Our cloud hosting service ensures the secure and scalable operation of your digital infrastructure. We provide a dependable cloud environment to ensure your applications run seamlessly and efficiently.</p>
                            </div>
                            <div className='ftrWrapper'>
                                <div className='ftrPoints'>
                                    <ul>
                                        <li>
                                            <span>Why opt for cloud: </span>
                                            <li>Flexible pricing:
                                            Cloud hosting is much more flexible in pricing compared to hosting on your own servers. This is mainly because you pay as you go, so your costs will increase only if your customer base does too.
                                            </li>
                                            <li>Flexible pricing: 
                                            Cloud hosting is much more flexible in pricing compared to hosting on your own servers. This is mainly because you pay as you go, so your costs will increase only if your customer base does too.
                                            </li>
                                            <li>Less responsibility for you: 
                                            Cloud solutions come with built-in scalability, security, and SLAs for reliability, recovery, and fault protection. Achieving and maintaining these would be impossible without a large team of developers and high costs.
                                            </li>
                                            <li>Our Cloud services: 
                                            Omellate makes your hosting and SaaS architecture needs hassle-free and efficient. Our experts cover a range of cloud platforms and services. We ensure your business runs like a well-oiled machine.
                                            </li>
                                        </li>
                                    </ul>
                                    <ul className='textRight'>
                                        <li>
                                            <span>WordPress Hosting: </span>
                                            <li>Single WP on WP Engine :
                                            With WP Engine, we offer dedicated hosting for single WordPress sites. Whether you’re running a personal blog, a business website, or an e-commerce store, you can count on our hosting to deliver top-notch performance, security, and scalability. 
                                            </li>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <span>Complete SaaS Architecture: </span>
                                            <li>Database Management with AWS RDS :</li>
                                            Managing databases can be daunting, but with Amazon RDS, we've got you covered. We handle the setup and management, ensuring high availability, robust security, and automated backups. Your databases will be in safe hands, scaling seamlessly as your business grows.
                                        </li>
                                    </ul>
                                    <ul className='textRight'>
                                        <li>
                                            <span>Storage Solutions with AWS S3 : </span>
                                            Think of AWS S3 as your virtual vault – secure, durable, and scalable. Whether you need storage for backups, data archiving, or distribution, we use Amazon S3 to make sure your data is always accessible and protected. It’s peace of mind knowing your data is safe and sound.
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <span>Containerized Setup with AWS ECS : </span>
                                            <p>
                                            Deploying containerized applications might sound complex, but with Amazon ECS, it's a breeze. Our team specializes in efficient management of Docker containers, ensuring smooth deployment and scaling of your applications. It’s like having a magic box that keeps everything running perfectly.
                                            </p>
                                        </li>
                                    </ul>

                                    <ul className='textRight'>
                                        <li>
                                            <span>Auto Scaling with AWS Auto Scaling  : </span>
                                            Traffic can be unpredictable, but with AWS Auto Scaling, you’re always prepared. We ensure your applications have the right amount of resources, automatically adjusting capacity based on demand. This improves fault tolerance and keeps costs in check. It’s like having a smart system that knows exactly what you need.
                                        </li>
                                    </ul>

                                    <ul>
                                        <li>
                                            <span>Deployment Services : </span>
                                            <li>
                                            Buddy.works: 
                                            Speed up your development and deployment process with Buddy.works. This automation platform is like having an extra team member who’s always on top of things, ensuring continuous integration and continuous deployment (CI/CD). Your workflow will be faster and more efficient.
                                            </li>
                                            <li>
                                            AWS CodeDeploy :
                                            Deploying applications smoothly is crucial, and AWS CodeDeploy makes it happen. We manage your deployments to EC2 instances, Lambda functions, or on-premises servers, ensuring everything goes off without a hitch. It’s like having a deployment wizard ensuring everything is just right.
                                            </li>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </article>
                    <article className="serviceWidget srcvWdgAlt">
                        <div className='wrapIner'>
                            <h2 className='h2 clrTheme'>Why Choose Cloud Hosting?</h2>
                            <ul className='ftrList'>
                                <li><span>Scalability:</span> Easily adjust resources as your business grows.</li>
                                <li><span>Reliability:</span> 24/7 availability and data redundancy.</li>
                                <li><span>Security:</span> Robust data protection and compliance.</li>
                                <li><span>Cost-Efficiency:</span> Pay only for the resources you use</li>
                                <li><span>Flexibility:</span> Scale up or down based on demand.</li>
                                <li>Our cloud hosting service offers numerous advantages, including scalability, reliability, security, cost-efficiency, and flexibility.</li>
                            </ul>
                        </div>
                    </article>
                    <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
                        <div className='wrapIner'>
                            <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
                            <h2 className='h2'>Why Opt for Omelatte?</h2>
                            <div className='decpWrap'>
                                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </>
    );
}

export default MvpDevelopment;