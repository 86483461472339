import React, { useEffect } from 'react';
import membersyImg from '../../assets/images/membersyImg.png';

const Membersy = () => {

  // Set the page Title for SEO purpose
  useEffect(() => {
    document.title = "Membersy | Omelatte";
  }, []);

  return (
    <>
      <section className='serviceDetailSection'>
        <div className='container'>
          <header className='sectionHeader txtCenter'>
            <div className='imgWrap txtCenter portfolioImgWrap'>
              <img src={membersyImg} className='imgFluid' alt="image description" />
              <ul className='webLink'>
                <li className='active'><a href="https://membersy.com/" target="_blank" rel='noreferrer'>visit website</a></li>
              </ul>
            </div>

            <h1 className='h1'>
              Membersy
            </h1>
          </header>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
            <div className='wrapIner'>
              <span className='txtTag txtUppercase'>Over View</span>
              <div className='decpWrap'>
                <p>
                  Membersy connects individuals to affordable healthcare and empowers providers
                  with the tools and support they need to build lasting patient relationships.
                  The site is powered by a robust tech stack, including PHP Laravel for the backend,
                  ReactJS for the front-end, and MySQL for database management. By utilizing Redis for
                  efficient caching and AWS services like RDS for database backups and S3 for scalable
                  storage, Membersy ensures seamless, reliable performance. The platform is containerized
                  with Docker and orchestrated with Kubernetes for easy scalability. Payment handling
                  is streamlined with Stripe, and high availability is supported by load balancers,
                  making it a scalable membership management platform designed for the modern era.
                  Enhanced security measures like Oauth and JWT authentication safeguard user data.
                </p>
              </div>
              <div className='portfolioIcons'>
                <i class='fab fa-laravel laravelIcon'></i>
                <i class='fab fa-react reactIcon'></i>
                <i class="fab fa-aws awsIcon"></i>
                <i class="	fab fa-docker dockerIcon"></i>
                <i class="fab fa-cc-stripe stripeIcon"></i>
              </div>
            </div>
          </article>
          <article className="serviceWidget srcvWdgAlt">
            <div className='wrapIner'>
              <h2 className='h2 clrTheme'>Why Choose Cloud Hosting?</h2>
              <ul className='ftrList'>
                <li><span>Scalability:</span> Easily adjust resources as your business grows.</li>
                <li><span>Reliability:</span> 24/7 availability and data redundancy.</li>
                <li><span>Security:</span> Robust data protection and compliance.</li>
                <li><span>Cost-Efficiency:</span> Pay only for the resources you use</li>
                <li><span>Flexibility:</span> Scale up or down based on demand.</li>
                <li>Our cloud hosting service offers numerous advantages, including scalability, reliability, security, cost-efficiency, and flexibility.</li>
              </ul>
            </div>
          </article>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
            <div className='wrapIner'>
              <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
              <h2 className='h2'>Why Opt for Omelatte?</h2>
              <div className='decpWrap'>
                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
}

export default Membersy;