import React, { useEffect } from 'react';
import Img01 from '../../assets/images/img03.svg';

const MvpDevelopment = () => {

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "UX/UI Design | Omelatte";
    }, []);

    return (
        <>
            <section className='serviceDetailSection'>
                <div className='container'>
                    <header className='sectionHeader txtCenter'>
                        <div className='imgWrap txtCenter'>
                            <img src={Img01} className='imgFluid' alt="image description" />
                        </div>
                        <h1 className='h1'>
                            <span className='txtOutline dBlock'>UX/UI </span>
                            Design
                        </h1>
                        <p>Elevating digital user experiences.</p>
                    </header>
                    <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
                        <div className='wrapIner'>
                            <span className='txtTag txtUppercase'>Start a project</span>
                            <h2 className='h2'>Elevating Digital User Experiences</h2>
                            <div className='decpWrap'>
                                <p>Our UX/UI design service is all about creating exceptional experiences for your users. We focus on captivating design and user-centric interfaces to enhance engagement and satisfaction.</p>
                            </div>

                            <div className='ftrWrapper'>
                                <div className='ftrPoints'>
                                    <ul>
                                        <li>
                                            <span>UX/UI Consulting: </span>
                                            Receive practical UX/UI insights and design assessments for resolving design obstacles and enhancing user experience. Tap into our proficient guidance to unleash your digital capabilities.
                                            <ul>
                                                <li>
                                                    <span className='ftrSubPoint'>Deliverables: </span>
                                                </li>
                                                <li>
                                                    • Evaluation of Design
                                                </li>
                                                <li>
                                                    • Development of Design Strategy
                                                </li>
                                                <li>
                                                    • Workshops with Stakeholders and Users
                                                </li>
                                                <li>
                                                    • Generation and Recommendations
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul className='textRight'>
                                        <li>
                                            <span> UX/UI Design: </span>
                                            Enhance user engagement through wireframes and interface prototypes, seamlessly translating designs into tangible experiences. Transition from conceptual sketches to dynamic prototypes, infusing your vision with uniqueness.
                                            <ul>
                                                <li>
                                                    <span className='ftrSubPoint'>Deliverables: </span>
                                                </li>
                                                <li>
                                                    • Wireframes & Meticulous Mockups
                                                </li>
                                                <li>
                                                    • Swift Prototyping
                                                </li>
                                                <li>
                                                    • Visual Design
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <span>Design Systems: </span>
                                            Harmonize design elements, create brand-centric standards, and craft UI elements to address brand variance and design disarray. We streamline brand coherence across every interaction and restore design harmony.
                                            <ul>
                                                <li>
                                                    <span className='ftrSubPoint'>Deliverables: </span>
                                                </li>
                                                <li>
                                                    • Development of UI Kit & Style Guide
                                                </li>
                                                <li>
                                                    • Creation of Pattern Library
                                                </li>
                                                <li>
                                                    • Documenting Components
                                                </li>
                                                <li>
                                                    • Implementing Version Control
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <ul className='textRight'>
                                        <li>
                                            <span>Research and Usability Testing: </span>
                                            Receive thorough user insights to pinpoint challenges and steer your product strategy with evidence-based insights. Our research empowers you with clarity and conviction.
                                            <ul>
                                                <li>
                                                    <span className='ftrSubPoint'>Deliverables: </span>
                                                </li>
                                                <li>
                                                    • Interviews and Surveys
                                                </li>
                                                <li>
                                                    • Evaluating Usability
                                                </li>
                                                <li>
                                                    • Analyzing Heatmaps and Click Behavior
                                                </li>
                                                <li>
                                                    • Experimenting with A/B Tests
                                                </li>
                                                <li>
                                                    • Examining Competitors
                                                </li>
                                            </ul>
                                            <ul>
                                                <li>
                                                How Do We Provide Excellence With Our Standard UI & UX Design Services?
                                                </li>
                                                <li>
                                                For more than ten years, we've honed our approach to providing top-notch UI and UX services. Our skilled designers follow industry standards and workflows to deliver a tailored product that meets your requirements on time. With a diverse toolkit, deep domain knowledge, and a focus on client satisfaction, we blend creativity with reliability and speed, ensuring excellence in every project. 
                                                </li>
                                                <ul>
                                                <li>
                                                    <span className='ftrSubPoint'>Our Process: </span>
                                                </li>
                                                <li>
                                                    <span>•Establish: </span>
                                                    Commence development by outlining system needs, making initial tech choices, and outlining user roles and personas.
                                                </li>
                                                <span> •Blueprint : </span>
                                                    Craft the initial aesthetics of your product, design user experience and journey, prioritize features, and strategize for release.
                                                <li>
                                                    <span>•Execute: </span>
                                                    Progress with intricate, development specifications, estimates on time and cost, a deployment strategy, and a team of skilled experts from a leading platform, primed to implement your strategy.
                                                </li>
                                                <li>
                                                    <span>•Build: </span>
                                                    During the product development process, monitor quality assurance, DevOps, and deployment progress using your preferred project management tool.
                                                </li>
                                            </ul>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className="serviceWidget srcvWdgAlt">
                        <div className='wrapIner'>
                            <h2 className='h2 clrTheme'>Our Approach to UX/UI Design</h2>
                            <ul className='ftrList'>
                                <li><span>User-Centric:</span> Prioritizing user needs and preferences.</li>
                                <li><span>Visual Appeal:</span> Crafting stunning and visually appealing designs</li>
                                <li><span>Usability:</span> Ensuring intuitive and efficient interaction.</li>
                                <li><span>Brand Identity:</span> Aligning designs with your brand's identity</li>
                                <li><span>Testing & Iteration:</span> Continuously improving the user experience.</li>
                                <li>Our approach to UX/UI design is centered on creating interfaces that resonate with users and drive positive interactions.</li>
                            </ul>
                        </div>
                    </article>
                    <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
                        <div className='wrapIner'>
                            <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
                            <h2 className='h2'>Why Opt for Omelatte?</h2>
                            <div className='decpWrap'>
                                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </>
    );
}

export default MvpDevelopment;