import React, { useEffect } from 'react';
import kidduThemeImg from "../../assets/images/kidduThemeImg.png";

const KidduTheme = () => {

  // Set the page Title for SEO purpose
  useEffect(() => {
    document.title = "Kiddu Theme| Omelatte";
  }, []);

  return (
    <>
      <section className='serviceDetailSection'>
        <div className='container'>
          <header className='sectionHeader txtCenter'>
            <div className='imgWrap txtCenter portfolioImgWrap'>
              <img src={kidduThemeImg} className='imgFluid' alt="description" />
              <ul className='webLink'>
                <li className='active'><a href="https://themes.shopify.com/designers/1o1development" target="_blank" rel='noreferrer'>visit website</a></li>
              </ul>
            </div>
            <h1 className='h1'>
              Kiddu Theme
            </h1>
          </header>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
            <div className='wrapIner'>
              <span className='txtTag txtUppercase'>Over View</span>
              <div className='decpWrap'>
                <p>
                  The Kidu Theme is a playful, vibrant theme designed with children’s clothing and toy
                  stores in mind. Built on Shopify’s Liquid framework with Vanilla JS for smooth
                  interactions and improved load times, Kidu is created to handle high volumes of
                  products while maintaining speed and performance. Customizable sections, including
                  featured collections, animated product sliders, and playful color palettes, allow
                  businesses to capture a young, dynamic aesthetic that appeals to both kids and parents.
                </p>
                <p>
                  SEO-friendly features like lazy loading, clean code, and responsive design ensure that this
                  theme is optimized for search engines and accessible across devices, making it perfect for
                  family-oriented brands aiming to create a lively online shopping experience. Kidu is also
                  adaptable for seasonal promotions, offering a high level of flexibility to create themed
                  collections or sales sections.
                </p>
              </div>
              <div className='portfolioIcons'>
                <i class="fa-brands fa-shopify shopifyIcon"></i>
              </div>
            </div>
          </article>
          <article className="serviceWidget srcvWdgAlt">
            <div className='wrapIner'>
              <h2 className='h2 clrTheme'>Why Choose Cloud Hosting?</h2>
              <ul className='ftrList'>
                <li><span>Scalability:</span> Easily adjust resources as your business grows.</li>
                <li><span>Reliability:</span> 24/7 availability and data redundancy.</li>
                <li><span>Security:</span> Robust data protection and compliance.</li>
                <li><span>Cost-Efficiency:</span> Pay only for the resources you use</li>
                <li><span>Flexibility:</span> Scale up or down based on demand.</li>
                <li>Our cloud hosting service offers numerous advantages, including scalability, reliability, security, cost-efficiency, and flexibility.</li>
              </ul>
            </div>
          </article>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
            <div className='wrapIner'>
              <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
              <h2 className='h2'>Why Opt for Omelatte?</h2>
              <div className='decpWrap'>
                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
}

export default KidduTheme;