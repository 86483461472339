import React, { useEffect } from 'react';
import Img01 from '../../assets/images/img03.svg';

const SoftwareDevelopment = () => {

	// Set the page Title for SEO purpose
	useEffect(() => {
		document.title = "Software Development | Omelatte";  
	}, []);

    return (
        <>
            <section className='serviceDetailSection'>
                <div className='container'>
                    <header className='sectionHeader txtCenter'>
                        <div className='imgWrap txtCenter'>
                            <img src={Img01} className='imgFluid' alt="Description" />
                        </div>
                        <h1 className='h1'>
                            <span className='txtOutline dBlock'>Custom Software </span>
                            Development
                        </h1>
                        <p>
                            Crafting personalized software solutions to match your business needs
                            is where our team truly shines.
                        </p>
                    </header>
                    <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
                        <div className='wrapIner'>
                            <span className='txtTag txtUppercase'>Start a project</span>
                            <h2 className='h2'>Crafting Tailored Software Solutions</h2>
                            <div className='decpWrap'>
                                <p>Our software development services are all about turning your unique business needs into powerful custom solutions. We build applications that are not only robust and efficient but also scalable to grow with your business.</p>
                            </div>
                            <div className='ftrWrapper'>
                                <div className='ftrPoints'>
                                  <ul>
                                      <li><span>Custom Software Development:</span> Crafting personalized software solutions to match your business needs is where our team truly shines. We cover everything from thorough analysis to design, coding, testing, and continuous support, guaranteeing a seamless fit with your organization's requirements and a significant edge in efficiency and competitiveness.</li>
                                  </ul>
                                  <ul className='textRight'>
                                      <li><span>Software Application Development:</span> Utilizing our proficiency in diverse programming languages and frameworks, we create applications centered on users, enhancing operational efficiency and fostering business expansion. Our offerings encompass comprehensive analysis, design, development, integration, and migration, guaranteeing top-notch solutions tailored to your requirements.</li>
                                  </ul>
                                  <ul>
                                      <li><span>Software Integration and API Development:</span> Our team specializes in facilitating smooth communication among software applications via strong API development. We closely collaborate with clients to create tailored solutions, adhering to industry standards for secure and dependable communication. Our offerings encompass the entire process, emphasizing scalability and optimizing performance from design to maintenance.</li>
                                  </ul>
                                  <ul className='textRight'>
                                      <li><span>Software Maintenance and Support:</span> We guarantee the ongoing functionality of your software systems with routine updates, bug resolution, performance enhancement, security evaluations, and tech assistance. Our vigilant oversight reduces interruptions and boosts system dependability, freeing you to concentrate on essential business tasks.</li>
                                  </ul>
                                  <ul>
                                      <li><span>Quality Assurance and Testing:</span> The expertise of our QA specialists ensures the delivery of top-notch software solutions, covering various testing aspects like functionality, performance, security, and usability, guaranteeing dependable, secure, and issue-free products.</li>
                                  </ul>
                                </div>
                            </div>
                            <div className='footerText'>Collaborate with Omelatte to leverage the proficiency of our adept developers and experts, streamline your software operations, and unleash the maximum capabilities of your applications.</div>                            
                        </div>
                    </article>
                    <article className="serviceWidget srcvWdgAlt">
                        <div className='wrapIner'>
                            <h2 className='h2 clrTheme'>Our Expertise in Software Development</h2>
                            <ul className='ftrList'>
                                <li><span>Custom Software:</span> Tailored applications designed to fit your specific requirements.</li>
                                <li><span>Web Development:</span> Creating robust web applications for your online presence.</li>
                                <li><span>Mobile App Development:</span> Engaging users on iOS and Android platforms.</li>
                                <li><span>E-commerce Solutions:</span> Seamless online shopping experiences for your customers.</li>
                                <li><span>Legacy System Upgrades:</span> Modernizing and optimizing existing software.</li>
                                <li><span>Cloud Integration:</span> Harnessing the power of the cloud for scalability.</li>
                                <li><span>API Development:</span> Streamlining data exchange and integrations.</li>
                                <li><span>UI/UX Design:</span> Elevating user experiences with intuitive interfaces.</li>
                                <li><span>Quality Assurance & Testing:</span> Rigorous testing to ensure flawless performance.</li>
                                <li><span>Maintenance & Support:</span> Ongoing care to keep your software running smoothly.</li>
                                <li><span></span>These are our key areas of expertise in software development.</li>
                            </ul>
                        </div>
                    </article>
                    <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
                        <div className='wrapIner'>
                            <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
                            <h2 className='h2'>Why Opt for Omelatte?</h2>
                            <div className='decpWrap'>
                                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </>
    );
}

export default SoftwareDevelopment;