import React, { useEffect } from 'react';
import urbanThemeImg from "../../assets/images/urbanThemeImg.png";

const UrbanTheme = () => {

  // Set the page Title for SEO purpose
  useEffect(() => {
    document.title = "Urban Theme| Omelatte";
  }, []);

  return (
    <>
      <section className='serviceDetailSection'>
        <div className='container'>
          <header className='sectionHeader txtCenter'>
            <div className='imgWrap txtCenter portfolioImgWrap'>
              <img src={urbanThemeImg} className='imgFluid' alt="description" />
              <ul className='webLink'>
                <li className='active'><a href="https://themes.shopify.com/designers/1o1development" target="_blank" rel='noreferrer'>visit website</a></li>
              </ul>
            </div>
            <h1 className='h1'>
              Urban Theme
            </h1>
          </header>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
            <div className='wrapIner'>
              <span className='txtTag txtUppercase'>Over View</span>
              <div className='decpWrap'>
                <p>
                  Designed for modern, minimalist brands, the Urban Theme brings a sophisticated,
                  streamlined layout ideal for lifestyle and apparel stores targeting the fashion-forward.
                  Built with HTML, CSS, and Vanilla JS, this theme prioritizes performance and clarity,
                  focusing on a user experience that highlights quality visuals and product details
                  without distractions.
                </p>
                <p>
                  Urban’s highly customizable sections allow store owners to create an engaging,
                  brand-focused homepage with editorial-style layouts, product grids, and social media
                  integration for a cohesive brand story. For SEO and performance, Urban incorporates
                  lazy loading and lightweight coding structures that enhance site speed. This theme
                  offers mobile-first design principles, ensuring the store looks polished on any
                  device, while its clean lines and muted tones make it ideal for brands looking to
                  create a modern, elegant digital storefront.
                </p>
              </div>
              <div className='portfolioIcons'>
                <i class="fa-brands fa-shopify shopifyIcon"></i>
              </div>
            </div>
          </article>
          <article className="serviceWidget srcvWdgAlt">
            <div className='wrapIner'>
              <h2 className='h2 clrTheme'>Why Choose Cloud Hosting?</h2>
              <ul className='ftrList'>
                <li><span>Scalability:</span> Easily adjust resources as your business grows.</li>
                <li><span>Reliability:</span> 24/7 availability and data redundancy.</li>
                <li><span>Security:</span> Robust data protection and compliance.</li>
                <li><span>Cost-Efficiency:</span> Pay only for the resources you use</li>
                <li><span>Flexibility:</span> Scale up or down based on demand.</li>
                <li>Our cloud hosting service offers numerous advantages, including scalability, reliability, security, cost-efficiency, and flexibility.</li>
              </ul>
            </div>
          </article>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
            <div className='wrapIner'>
              <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
              <h2 className='h2'>Why Opt for Omelatte?</h2>
              <div className='decpWrap'>
                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
}

export default UrbanTheme;