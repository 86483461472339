import React, { useEffect } from 'react';
import Img01 from '../../assets/images/img03.svg';

const MvpDevelopment = () => {

  // Set the page Title for SEO purpose
  useEffect(() => {
    document.title = "Mobile Apps | Omelatte";
  }, []);

  return (
    <>
      <section className='serviceDetailSection'>
        <div className='container'>
          <header className='sectionHeader txtCenter'>
            <div className='imgWrap txtCenter'>
              <img src={Img01} className='imgFluid' alt="image description" />
            </div>
            <h1 className='h1'>
              <span className='txtOutline dBlock'>Mobile </span>
              Apps
            </h1>
            <p>Engaging users across devices.</p>
          </header>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
            <div className='wrapIner'>
              <span className='txtTag txtUppercase'>Start a project</span>
              <h2 className='h2'>Creating User-Centric Mobile Apps</h2>
              <div className='decpWrap'>
                <p>Our mobile app development service focuses on engaging users seamlessly across various devices. We design and build user-friendly, feature-rich mobile applications to expand your brand's reach.</p>
              </div>

              <div className='ftrWrapper'>
                <div className='ftrPoints'>
                  <ul>
                    <li>
                      <span>Product design: </span>
                      We analyze market trends and your brand identity to highlight your competitive edge.
                    </li>
                  </ul>
                  <ul className='textRight'>
                    <li>
                      <span>Mobile app consulting: </span>
                      From finalizing concepts to optimizing development costs, we guide you through every step.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span>Mobile app design: </span>
                      Our designers craft user-friendly interfaces and visually appealing designs to boost engagement and adoption.
                    </li>
                  </ul>
                  <ul className='textRight'>
                    <li>
                      <span>Mobile app development: </span>
                      With a track record of over 350 successful projects, our developers ensure fast, stable, and high-performing mobile apps.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span>Mobile app integration: </span>
                      Proficient in various APIs, we ensure seamless integration with backend systems and third-party software.
                    </li>
                  </ul>
                  <ul className='textRight'>
                    <li>
                      <span>Testing and QA: </span>
                      Our ISTQB-certified engineers conduct thorough testing covering functionality, performance, security, user experience, and accessibility.
                    </li>
                  </ul>
                  <ul>
                    <li>
                      <span>Maintenance and support: </span>
                      We provide post-launch support, compliance management, security updates, and app evolution services.
                    </li>
                  </ul>
                  <ul className='textRight'>
                    <li>
                      <span>Mobile app upgrading: </span>
                      Our developers revamp architectures, refactor code, and refresh legacy apps for improved performance and user experience.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </article>
          <article className="serviceWidget srcvWdgAlt">
            <div className='wrapIner'>
              <h2 className='h2 clrTheme'>What Sets Our Mobile Apps Apart</h2>
              <ul className='ftrList'>
                <li><span>User-Friendly:</span> Intuitive interfaces for effortless navigation.</li>
                <li><span>Cross-Platform:</span> Reach both iOS and Android users.</li>
                <li><span>Feature-Rich:</span> Enhance user engagement with robust functionalities.</li>
                <li><span>Brand Consistency:</span> Align app design with your brand identity.</li>
                <li><span>Testing & Quality:</span> Rigorous testing for reliable performance.</li>
                <li>Our mobile apps are designed for excellence, providing user-friendly experiences and robust features.</li>
              </ul>
            </div>
          </article>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
            <div className='wrapIner'>
              <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
              <h2 className='h2'>Why Opt for Omelatte?</h2>
              <div className='decpWrap'>
                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
}

export default MvpDevelopment;