import React, { useEffect } from 'react';
import shopMeatBoxImg from "../../assets/images/shopMeatBoxImg.png"

const ShopMeatBox = () => {

  // Set the page Title for SEO purpose
  useEffect(() => {
    document.title = "Shop Meat Box | Omelatte";
  }, []);

  return (
    <>
      <section className='serviceDetailSection'>
        <div className='container'>
          <header className='sectionHeader txtCenter'>
            <div className='imgWrap txtCenter portfolioImgWrap'>
              <img src={shopMeatBoxImg} className='imgFluid' alt="description" />
              <ul className='webLink'>
                <li className='active'><a href="https://shopmeatbox.com/" target="_blank" rel='noreferrer'>visit website</a></li>
              </ul>
            </div>
            <h1 className='h1'>
              ShopMeatBox
            </h1>
          </header>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
            <div className='wrapIner'>
              <span className='txtTag txtUppercase'>Over View</span>
              <div className='decpWrap'>
                <p>
                  ShopMeatBox’s Shopify store is optimized for the food industry, featuring custom
                  sections for product freshness and delivery options, as well as a subscription box
                  feature for regular orders. Built for reliability, it integrates Shopify Payments and
                  Stripe for secure transactions. The store’s layout includes dynamic filtering options
                  for specific meat types and is mobile-responsive, ensuring a streamlined experience
                  for users ordering from various devices.
                </p>
              </div>
              <div className='portfolioIcons'>
                <i class="fa-brands fa-shopify shopifyIcon"></i>
              </div>
            </div>
          </article>
          <article className="serviceWidget srcvWdgAlt">
            <div className='wrapIner'>
              <h2 className='h2 clrTheme'>Why Choose Cloud Hosting?</h2>
              <ul className='ftrList'>
                <li><span>Scalability:</span> Easily adjust resources as your business grows.</li>
                <li><span>Reliability:</span> 24/7 availability and data redundancy.</li>
                <li><span>Security:</span> Robust data protection and compliance.</li>
                <li><span>Cost-Efficiency:</span> Pay only for the resources you use</li>
                <li><span>Flexibility:</span> Scale up or down based on demand.</li>
                <li>Our cloud hosting service offers numerous advantages, including scalability, reliability, security, cost-efficiency, and flexibility.</li>
              </ul>
            </div>
          </article>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
            <div className='wrapIner'>
              <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
              <h2 className='h2'>Why Opt for Omelatte?</h2>
              <div className='decpWrap'>
                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
}

export default ShopMeatBox;