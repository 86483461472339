import React, { useEffect } from 'react';
import muslimDelightsImg from "../../assets/images/muslimDeligtsImg.png"

const MuslimDelights = () => {

  // Set the page Title for SEO purpose
  useEffect(() => {
    document.title = "Muslim Delights | Omelatte";
  }, []);

  return (
    <>
      <section className='serviceDetailSection'>
        <div className='container'>
          <header className='sectionHeader txtCenter'>
            <div className='imgWrap txtCenter portfolioImgWrap'>
              <img src={muslimDelightsImg} className='imgFluid' alt=" description" />
              <ul className='webLink'>
                <li className='active'><a href="https://muslimdelights.com/" target="_blank" rel='noreferrer'>visit website</a></li>
              </ul>
            </div>
            <h1 className='h1'>
              MuslimDelights
            </h1>
          </header>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
            <div className='wrapIner'>
              <span className='txtTag txtUppercase'>Over View</span>
              <div className='decpWrap'>
                <p>
                  This a wedding and catering business in Singapore. Omelatte has developed this site
                  using PHP Laravel and ReactJS, with DigitalOcean handling its hosting requirements.
                  This platform is designed to offer a streamlined, user-friendly experience, enhanced by
                  modern caching techniques and security protocols. The use of OAuth and JWT authentication
                  ensures user data is protected, while Cloudflare optimizes performance and provides
                  protection against cyber threats.
                </p>
              </div>
              <div className='portfolioIcons'>
                <i class='fab fa-laravel laravelIcon'></i>
                <i class='fab fa-react reactIcon'></i>
                <i class="fa-brands fa-digital-ocean"></i>
                <i class="fab fa-aws awsIcon"></i>
                <i class="fab fa-cc-stripe stripeIcon"></i>
                <i class="fa-brands fa-cloudflare cloudeIcon"></i>
              </div>
            </div>
          </article>
          <article className="serviceWidget srcvWdgAlt">
            <div className='wrapIner'>
              <h2 className='h2 clrTheme'>Why Choose Cloud Hosting?</h2>
              <ul className='ftrList'>
                <li><span>Scalability:</span> Easily adjust resources as your business grows.</li>
                <li><span>Reliability:</span> 24/7 availability and data redundancy.</li>
                <li><span>Security:</span> Robust data protection and compliance.</li>
                <li><span>Cost-Efficiency:</span> Pay only for the resources you use</li>
                <li><span>Flexibility:</span> Scale up or down based on demand.</li>
                <li>Our cloud hosting service offers numerous advantages, including scalability, reliability, security, cost-efficiency, and flexibility.</li>
              </ul>
            </div>
          </article>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
            <div className='wrapIner'>
              <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
              <h2 className='h2'>Why Opt for Omelatte?</h2>
              <div className='decpWrap'>
                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
}

export default MuslimDelights;