import React, { useEffect } from 'react';
import petBondImg from "../../assets/images/petBondImg.png"
const PetBond = () => {

  // Set the page Title for SEO purpose
  useEffect(() => {
    document.title = "Petbond | Omelatte";
  }, []);

  return (
    <>
      <section className='serviceDetailSection'>
        <div className='container'>
          <header className='sectionHeader txtCenter'>
            <div className='imgWrap txtCenter portfolioImgWrap'>
              <img src={petBondImg} className='imgFluid' alt="description" />
              <ul className='webLink'>
                <li className='active'><a href="https://mypetbond.com/" target="_blank" rel='noreferrer'>visit website</a></li>
              </ul>
            </div>
            <h1 className='h1'>
              PetBond
            </h1>
          </header>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
            <div className='wrapIner'>
              <span className='txtTag txtUppercase'>Over View</span>
              <div className='decpWrap'>
                <p>
                  It is a Pet Adoption portal to discover your next pet. Developed with PHP Laravel
                  and ReactJS, Petbond.com integrates AWS for its hosting and cloud infrastructure,
                  including RDS for database solutions and S3 for data storage. Redis is leveraged
                  for caching, and Stripe is used for smooth payment transactions. The architecture
                  ensures optimized performance through Cloudflare, offering fast load times and DDoS
                  protection. This platform, created for pet enthusiasts, combines intuitive design
                  with solid back-end infrastructure, delivering a seamless experience for users.
                </p>
              </div>
              <div className='portfolioIcons'>
                <i class='fab fa-laravel laravelIcon'></i>
                <i class='fab fa-react reactIcon'></i>
                <i class="fab fa-aws awsIcon"></i>
                <i class="	fab fa-docker dockerIcon"></i>
                <i class="fab fa-cc-stripe stripeIcon"></i>
                <i class="fa-brands fa-cloudflare cloudeIcon"></i>
              </div>
            </div>
          </article>
          <article className="serviceWidget srcvWdgAlt">
            <div className='wrapIner'>
              <h2 className='h2 clrTheme'>Why Choose Cloud Hosting?</h2>
              <ul className='ftrList'>
                <li><span>Scalability:</span> Easily adjust resources as your business grows.</li>
                <li><span>Reliability:</span> 24/7 availability and data redundancy.</li>
                <li><span>Security:</span> Robust data protection and compliance.</li>
                <li><span>Cost-Efficiency:</span> Pay only for the resources you use</li>
                <li><span>Flexibility:</span> Scale up or down based on demand.</li>
                <li>Our cloud hosting service offers numerous advantages, including scalability, reliability, security, cost-efficiency, and flexibility.</li>
              </ul>
            </div>
          </article>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
            <div className='wrapIner'>
              <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
              <h2 className='h2'>Why Opt for Omelatte?</h2>
              <div className='decpWrap'>
                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
}

export default PetBond;