import React, { useEffect } from 'react';
import connexTechImg from "../../assets/images/connexTechImg.png";

const ConnexisTechnology = () => {

  // Set the page Title for SEO purpose
  useEffect(() => {
    document.title = "Connexis Technology | Omelatte";
  }, []);

  return (
    <>
      <section className='serviceDetailSection'>
        <div className='container'>
          <header className='sectionHeader txtCenter portfolioImgWrap'>
            <div className='imgWrap txtCenter'>
              <img src={connexTechImg} className='imgFluid' alt="description" />
              <ul className='webLink'>
                <li className='active'><a href="https://www.connexistracking.com/" target="_blank" rel='noreferrer'>visit website</a></li>
              </ul>
            </div>
            <h1 className='h1'>
              ConnexisTechnologies
            </h1>
          </header>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
            <div className='wrapIner'>
              <span className='txtTag txtUppercase'>Over View</span>
              <div className='decpWrap'>
                <p>
                  Connexis Technologies is a GPS Satellite Vehicle Tracking System. It is an advanced
                  IoT-driven platform built with PHP Laravel and Angular, supported by AWS services,
                  including RDS and S3 for hosting and data storage. The integration of Docker and
                  Kubernetes ensures easy scalability and system resilience. With IoT and mobile app
                  support through Flutter, the platform is equipped to handle complex technologies
                  and provide real-time insights. Payments are securely processed via Stripe, and JWT
                  authentication ensures data security across devices.
                </p>
              </div>
              <div className='portfolioIcons'>
                <i class='fab fa-laravel laravelIcon'></i>
                <i class="fa-brands fa-angular angularIcon"></i>
                <i class="fab fa-aws awsIcon"></i>
                <i class="	fab fa-docker dockerIcon"></i>
                <i class="fab fa-cc-stripe stripeIcon"></i>
                <i class="fa-brands fa-flutter flutterIcon"></i>
              </div>
            </div>
          </article>
          <article className="serviceWidget srcvWdgAlt">
            <div className='wrapIner'>
              <h2 className='h2 clrTheme'>Why Choose Cloud Hosting?</h2>
              <ul className='ftrList'>
                <li><span>Scalability:</span> Easily adjust resources as your business grows.</li>
                <li><span>Reliability:</span> 24/7 availability and data redundancy.</li>
                <li><span>Security:</span> Robust data protection and compliance.</li>
                <li><span>Cost-Efficiency:</span> Pay only for the resources you use</li>
                <li><span>Flexibility:</span> Scale up or down based on demand.</li>
                <li>Our cloud hosting service offers numerous advantages, including scalability, reliability, security, cost-efficiency, and flexibility.</li>
              </ul>
            </div>
          </article>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
            <div className='wrapIner'>
              <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
              <h2 className='h2'>Why Opt for Omelatte?</h2>
              <div className='decpWrap'>
                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
}

export default ConnexisTechnology;