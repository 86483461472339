import React, { useEffect } from 'react';
import curatorImg from '../../assets/images/curatorImg.png';

const CuratorPaints = () => {

  // Set the page Title for SEO purpose
  useEffect(() => {
    document.title = "Curator Paints | Omelatte";
  }, []);

  return (
    <>
      <section className='serviceDetailSection'>
        <div className='container'>
          <header className='sectionHeader txtCenter'>
            <div className='imgWrap txtCenter portfolioImgWrap'>
              <img src={curatorImg} className='imgFluid' alt="description" />
              <ul className='webLink'>
                <li className='active'><a href="https://www.curatorpaints.ie/" target="_blank" rel='noreferrer'>visit website</a></li>
              </ul>
            </div>
            <h1 className='h1'>
              Curator Paints
            </h1>
          </header>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
            <div className='wrapIner'>
              <span className='txtTag txtUppercase'>Over View</span>
              <div className='decpWrap'>
                <p>
                  Curator Paints’ Shopify store is developed for creativity, featuring an interactive
                  color selection tool that lets customers experiment with hues before purchase.
                  Integrated with advanced dynamic filtering and quick view options, it enables
                  quick access to specific colors and finishes. Secure checkout is handled through
                  Shopify Payments and Stripe, ensuring a smooth purchase experience, with the store
                  optimized for desktop and mobile to accommodate interior designers and DIY enthusiasts
                  alike.
                </p>
              </div>
              <div className='portfolioIcons'>
                <i class="fa-brands fa-shopify shopifyIcon"></i>
              </div>
            </div>
          </article>
          <article className="serviceWidget srcvWdgAlt">
            <div className='wrapIner'>
              <h2 className='h2 clrTheme'>Why Choose Cloud Hosting?</h2>
              <ul className='ftrList'>
                <li><span>Scalability:</span> Easily adjust resources as your business grows.</li>
                <li><span>Reliability:</span> 24/7 availability and data redundancy.</li>
                <li><span>Security:</span> Robust data protection and compliance.</li>
                <li><span>Cost-Efficiency:</span> Pay only for the resources you use</li>
                <li><span>Flexibility:</span> Scale up or down based on demand.</li>
                <li>Our cloud hosting service offers numerous advantages, including scalability, reliability, security, cost-efficiency, and flexibility.</li>
              </ul>
            </div>
          </article>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
            <div className='wrapIner'>
              <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
              <h2 className='h2'>Why Opt for Omelatte?</h2>
              <div className='decpWrap'>
                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
}

export default CuratorPaints;