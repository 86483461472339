import React, { useEffect } from 'react';
import cuesImg from "../../assets/images/cuesImg.png";

const GetCues = () => {

  // Set the page Title for SEO purpose
  useEffect(() => {
    document.title = "GetCues | Omelatte";
  }, []);

  return (
    <>
      <section className='serviceDetailSection'>
        <div className='container'>
          <header className='sectionHeader txtCenter'>
            <div className='imgWrap txtCenter portfolioImgWrap'>
              <img src={cuesImg} className='imgFluid' alt="description" />
              <ul className='webLink'>
                <li className='active'><a href="https://www.getcues.com/" target="_blank" rel='noreferrer'>visit website</a></li>
              </ul>
            </div>
            <h1 className='h1'>
              GetCues
            </h1>
          </header>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
            <div className='wrapIner'>
              <span className='txtTag txtUppercase'>Over View</span>
              <div className='decpWrap'>
                <p>
                  GetCues.com offers employee recognition tools that assist companies in boosting
                  employee engagement and performance. We have built this website with a combination
                  of PHP Laravel and ReactJS, delivering a dynamic front-end and powerful back-end.
                  A Mobile App developed in Flutter complements the web experience, ensuring accessibility
                  across devices. Key integrations like Stripe handle secure payment processing, while
                  Redis is used for real-time caching, making the platform highly responsive.
                  Hosting on AWS guarantees scalability, while OAuth and JWT protocols ensure that user
                  data remains safe and secure.
                </p>
              </div>
              <div className='portfolioIcons'>
                <i class='fab fa-laravel laravelIcon'></i>
                <i class='fab fa-react reactIcon'></i>
                <i class="fab fa-aws awsIcon"></i>
                <i class="fa-brands fa-flutter flutterIcon"></i>
                <i class="fab fa-cc-stripe stripeIcon"></i>
              </div>
            </div>
          </article>
          <article className="serviceWidget srcvWdgAlt">
            <div className='wrapIner'>
              <h2 className='h2 clrTheme'>Why Choose Cloud Hosting?</h2>
              <ul className='ftrList'>
                <li><span>Scalability:</span> Easily adjust resources as your business grows.</li>
                <li><span>Reliability:</span> 24/7 availability and data redundancy.</li>
                <li><span>Security:</span> Robust data protection and compliance.</li>
                <li><span>Cost-Efficiency:</span> Pay only for the resources you use</li>
                <li><span>Flexibility:</span> Scale up or down based on demand.</li>
                <li>Our cloud hosting service offers numerous advantages, including scalability, reliability, security, cost-efficiency, and flexibility.</li>
              </ul>
            </div>
          </article>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
            <div className='wrapIner'>
              <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
              <h2 className='h2'>Why Opt for Omelatte?</h2>
              <div className='decpWrap'>
                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
}

export default GetCues;