import React, { useEffect } from 'react';
import sparkNewsletterImg from "../../assets/images/sparkNewsletterImg.png";


const NewsletterEmailSync = () => {

  // Set the page Title for SEO purpose
  useEffect(() => {
    document.title = "Newsletter Email Sync | Omelatte";
  }, []);

  return (
    <>
      <section className='serviceDetailSection'>
        <div className='container'>
          <header className='sectionHeader txtCenter'>
            <div className='imgWrap txtCenter portfolioImgWrap'>
              <img src={sparkNewsletterImg} className='imgFluid' alt=" description" />
              <ul className='webLink'>
                <li className='active'><a href="https://apps.shopify.com/partners/salespark" target="_blank" rel='noreferrer'>visit website</a></li>
              </ul>
            </div>
            <h1 className='h1'>
              Newsletter Email Sync
            </h1>
          </header>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
            <div className='wrapIner'>
              <span className='txtTag txtUppercase'>Over View</span>
              <div className='decpWrap'>
                <p>
                  This Email Sync application is designed for stores focused on effective email marketing
                  and customer engagement. Using the MERN stack, this app allows seamless synchronization
                  of Shopify subscribers across multiple platforms like Mailchimp and other email marketing
                  tools. This means that store owners can keep their email lists up-to-date without manual exports
                  or imports. With reliable syncing and compatibility with popular platforms, this app empowers
                  businesses to run targeted, timely campaigns that help retain and convert customers, all while
                  maintaining a simple and efficient workflow.
                </p>
              </div>
              <div className='portfolioIcons'>
                <i class='fab fa-react reactIcon'></i>
                <i class="fa-brands fa-cloudflare cloudeIcon"></i>
                <i class="fa-brands fa-node-js nodejsIcon"></i>
              </div>
            </div>
          </article>
          <article className="serviceWidget srcvWdgAlt">
            <div className='wrapIner'>
              <h2 className='h2 clrTheme'>Why Choose Cloud Hosting?</h2>
              <ul className='ftrList'>
                <li><span>Scalability:</span> Easily adjust resources as your business grows.</li>
                <li><span>Reliability:</span> 24/7 availability and data redundancy.</li>
                <li><span>Security:</span> Robust data protection and compliance.</li>
                <li><span>Cost-Efficiency:</span> Pay only for the resources you use</li>
                <li><span>Flexibility:</span> Scale up or down based on demand.</li>
                <li>Our cloud hosting service offers numerous advantages, including scalability, reliability, security, cost-efficiency, and flexibility.</li>
              </ul>
            </div>
          </article>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
            <div className='wrapIner'>
              <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
              <h2 className='h2'>Why Opt for Omelatte?</h2>
              <div className='decpWrap'>
                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
}

export default NewsletterEmailSync;