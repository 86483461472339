import React, { useEffect } from 'react';
import staffedUpImg from "../../assets/images/staffedUpImg.png"

const StaffedUp = () => {

  // Set the page Title for SEO purpose
  useEffect(() => {
    document.title = "GetCues | Omelatte";
  }, []);

  return (
    <>
      <section className='serviceDetailSection'>
        <div className='container'>
          <header className='sectionHeader txtCenter'>
            <div className='imgWrap txtCenter portfolioImgWrap'>
              <img src={staffedUpImg} className='imgFluid' alt="description" />
              <ul className='webLink'>
                <li className='active'><a href="https://staffedup.com/" target="_blank" rel='noreferrer'>visit website</a></li>
              </ul>
            </div>
            <h1 className='h1'>
              StaffedUp
            </h1>
          </header>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
            <div className='wrapIner'>
              <span className='txtTag txtUppercase'>Over View</span>
              <div className='decpWrap'>
                <p>
                  StaffedUp.com is a hiring software to help business owners to recruit the best employees.
                  The website leverages the power of Python for backend processes, while Angular and
                  ReactJS bring a smooth, modern front-end user experience. The integration of Stripe
                  for payments and a microservices-based architecture ensures that this staffing platform
                  can scale and manage high traffic efficiently. The cloud infrastructure, paired with
                  tools like Redis and Cloudflare, allows for high performance, fast page loads, and
                  a secure experience.
                </p>
              </div>
              <div className='portfolioIcons'>
                <i class="fa-brands fa-python pythonIcon"></i>
                <i class="fa-brands fa-angular angularIcon"></i>
                <i class='fab fa-react reactIcon'></i>
                <i class="fab fa-cc-stripe stripeIcon"></i>
                <i class="fa-brands fa-cloudflare cloudeIcon"></i>
              </div>
            </div>
          </article>
          <article className="serviceWidget srcvWdgAlt">
            <div className='wrapIner'>
              <h2 className='h2 clrTheme'>Why Choose Cloud Hosting?</h2>
              <ul className='ftrList'>
                <li><span>Scalability:</span> Easily adjust resources as your business grows.</li>
                <li><span>Reliability:</span> 24/7 availability and data redundancy.</li>
                <li><span>Security:</span> Robust data protection and compliance.</li>
                <li><span>Cost-Efficiency:</span> Pay only for the resources you use</li>
                <li><span>Flexibility:</span> Scale up or down based on demand.</li>
                <li>Our cloud hosting service offers numerous advantages, including scalability, reliability, security, cost-efficiency, and flexibility.</li>
              </ul>
            </div>
          </article>
          <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
            <div className='wrapIner'>
              <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
              <h2 className='h2'>Why Opt for Omelatte?</h2>
              <div className='decpWrap'>
                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
              </div>
            </div>
          </article>
        </div>
      </section>
    </>
  );
}

export default StaffedUp;