import React, { useEffect } from 'react';
import Img01 from '../../assets/images/img03.svg';

const MvpDevelopment = () => {

    // Set the page Title for SEO purpose
    useEffect(() => {
        document.title = "Support & Maintenance | Omelatte";
    }, []);

    return (
        <>
            <section className='serviceDetailSection'>
                <div className='container'>
                    <header className='sectionHeader txtCenter'>
                        <div className='imgWrap txtCenter'>
                            <img src={Img01} className='imgFluid' alt="image description" />
                        </div>
                        <h1 className='h1'>
                            <span className='txtOutline dBlock'>Support </span>
                            & Maintenance
                        </h1>
                        <p>Ongoing care for your success.</p>
                    </header>
                    <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="top">
                        <div className='wrapIner'>
                            <span className='txtTag txtUppercase'>Start a project</span>
                            <h2 className='h2'>Reliable Support & Maintenance Services</h2>
                            <div className='decpWrap'>
                                <p>Our commitment to your success goes beyond project launch. We offer ongoing support and maintenance services to keep your solutions running smoothly and up-to-date.</p>
                            </div>

                            <div className='ftrWrapper'>
                                <div className='ftrPoints'>
                                    <ul>
                                        <li>
                                            <span>Adaptability: </span>
                                            Our Customer Care crew offers personalized assistance and diverse engagement options tailored to your company's requirements and financial goals.
                                        </li>
                                    </ul>
                                    <ul className='textRight'>
                                        <li>
                                            <span>Expansion Capability: </span>
                                            We aim to enhance customer support on a larger scale, adhering to our Compliance Matrix and Service Level Agreements for easily customizable solutions.
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <span>Preemptive Surveillance: </span>
                                            Utilize our software upkeep and support services for a comprehensive insight into network, application, and security system health, preempting potential vulnerabilities.
                                        </li>
                                    </ul>
                                    <ul className='textRight'>
                                        <li>
                                            <span>Efficient Crisis Resolution: </span>
                                            Stay ahead of unforeseen hiccups with our rapid response, swiftly resolving issues and restoring operational functionality to your system.
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <span>Post-Release Software Maintenance and Support: </span>
                                            <p>
                                                Omellate provides application upkeep and assistance to guarantee the smooth, secure, and efficient operation of your solution. Our team aids in enhancing agility, streamlining support, and ensuring ongoing improvement while maintaining UX coherence.
                                            </p>
                                            <p>
                                                Typically, we employ an Atlassian JIRA-based Help Desk system to organize, document, and manage support queries. This system empowers our maintenance and support team to promptly address user concerns, monitor task progress, facilitate communication, and maintain a comprehensive ticket history.
                                            </p>
                                        </li>
                                        <li>Why Join Omellate ?
                                            <li>
                                                An exceptional team providing a versatile and adaptable global delivery structure.
                                            </li>
                                            <li>
                                            Evidence-backed, reliable delivery methods and business strategies.
                                            </li>
                                            <li>
                                            Commonly used tools and top-notch methodologies in the industry.
                                            </li>
                                            <li>
                                            Resources not tied to any specific technology.
                                            </li>
                                            <li>
                                            Continuous system availability and support spanning all time zones.
                                            </li>
                                            <li>
                                            Cutting-edge software engineering norms and practices.
                                            </li>
                                        </li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                    </article>
                    <article className="serviceWidget srcvWdgAlt">
                        <div className='wrapIner'>
                            <h2 className='h2 clrTheme'>Why Invest in Support & Maintenance?</h2>
                            <ul className='ftrList'>
                                <li><span>Performance Optimization:</span> Ensure your software operates at its best.</li>
                                <li><span>Security Updates:</span> Protect against vulnerabilities and threats.</li>
                                <li><span>Scalability:</span> Adapt your solutions to changing business needs</li>
                                <li><span>User Support:</span> Assist users with technical issues and inquiries.</li>
                                <li><span>Continuous Improvement:</span> Keep your software current and competitive.</li>
                                <li>Support and maintenance are essential investments in the long-term success and performance of your digital solutions.</li>
                            </ul>
                        </div>
                    </article>
                    <article className="serviceWidget bgGradiantGray bdrGradiant gradGray txtCenter fontPoppins" data-tag="bottom">
                        <div className='wrapIner'>
                            <a href="/pricing" className='txtTag txtUppercase'>Hire Omelatte</a>
                            <h2 className='h2'>Why Opt for Omelatte?</h2>
                            <div className='decpWrap'>
                                <p>Omelatte is your choice for success. Our seasoned experts provide tailored, innovative solutions that push boundaries and deliver standout results. We're not just service providers; we're your innovation partners committed to turning your vision into reality.</p>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        </>
    );
}

export default MvpDevelopment;